@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.blinking {
  animation: blink 1s linear infinite;
}

.animation-wrapper {
  width: 100%;
  max-width: 500px; /* Adjust this value to your desired maximum width */
  margin: 2rem auto 0 auto;
}
@media (max-width: 640px) {
  .hero-content {
    margin-bottom: 2rem;
  }

  .hero-images {
    position: relative;
    top: 0 !important;
  }

  .hero-images img:first-child {
    width: 90%;
    max-width: none;
  }

  .hero-images img:last-child {
    width: 30%;
    max-width: none;
    bottom: -5%;
    left: 0;
  }
}
@media (min-width: 1024px) {
  .animation-wrapper {
    margin-top: 0;
    position: relative;
    top: -90px;
  }
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #3B82F6;
  cursor: pointer;
  border-radius: 50%;
}

input[type="range"]::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #3B82F6;
  cursor: pointer;
  border-radius: 50%;
}
/* ... rest of your CSS ... */
@keyframes shake {
  0%, 100% {
    transform: rotate(0deg);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: rotate(-2deg);
  }
  20%, 40%, 60%, 80% {
    transform: rotate(2deg);
  }
}

.animate-shake {
  animation: shake 0.5s;
}

.gradient-background {
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.polygon {
  position: absolute;
  width: 100%;
  height: 100%;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  opacity: 0.3;
  z-index: -1;
}

.polygon-1 {
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  transform: scale(1.2) rotate(45deg);
}

.polygon-2 {
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  transform: scale(1.4) rotate(60deg);
}

.polygon-3 {
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  transform: scale(1.6) rotate(75deg);
}

@media (max-width: 640px) {
  .form-container {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
}
.chatbot-textbox {
  transition: height 0.3s ease-in-out;
}

.expand-height {
  height: auto !important;
  max-height: 600px; /* Adjust the maximum height as needed */
}
.transform-gpu {
  transform: translateZ(0);
}
/* Add these styles to your CSS file */
.introjs-helperLayer, .introjs-tooltip {
  z-index: 10000 !important;
}

.add-owner-button-wrapper, .review-button-wrapper {
  position: relative;
  z-index: 10001;
}
